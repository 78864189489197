import DBAbstract from "./DBAbstract";

class KDMaxProductRepository extends DBAbstract {
    constructor() {
        super();
        this.record = {
            kdmax_product: 'kdmax_product'
        };
    }

    async addProduct (data) { return await this.add(this.record.kdmax_product, data) }

    async updateProduct (data) { return await this.add(this.record.kdmax_product, data, true) }

    async deleteProduct (id) { return await this.delete(this.record.kdmax_product, id) }

    async deleteAllProduct () {return await this.truncate(this.record.kdmax_product); }

    async getImportedProduct (id) { return await this.readById(this.record.kdmax_product, id); }

    async getAllProducts(roomId) {
        return await this.readById( this.record.kdmax_product, roomId, "room");
    }
}

export default KDMaxProductRepository;