import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useLoginWithTokenMutation} from 'components/customer/Auth/store/accountSlice';
import {Loader} from 'shared/helpers';

const TokenHandler = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get('value');

    const [loginWithToken, {data, isError, isSuccess}] =
        useLoginWithTokenMutation();

    useEffect(() => {
        if (token) {
            void loginWithToken(token);
        } else {
            navigate('/v2/login');
        }
    }, [token]);

    useEffect(() => {
        if (isError) {
            navigate('/v2/login');
            return;
        }

        if (isSuccess && data) {
            if (data.success) {
                // to reload the page
                window.location.href = `/v2`;
            } else {
                navigate('/v2/login');
                return;
            }
        }
    }, [data, isError, isSuccess]);

    return <Loader loader={true} />;
};

export default TokenHandler;
