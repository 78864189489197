import React from 'react';
import styled from 'styled-components';
import {useProductContext} from 'contexts';
import {ProductBackgroundImage} from 'components/customer/Product/ProductStyled';
import {Product} from 'components/customer/Product/entity';

const NoPreview3DPlaceholder = () => {
    const {getProductInfo, getMaterialOptions} = useProductContext<{
        getProductInfo: (key: string) => string;
        productDataStore: {current?: Product};
        getMaterialOptions: () => {
            exterior_color: string;
            hor_grain_ext: number;
        };
    }>();
    const materialOptions = getMaterialOptions();
    const isSvg = getProductInfo('image').endsWith('.svg');

    return (
        <Preview3DContainer>
            <PlaceholderImageContainer>
                <ProductBackgroundImage
                    image={
                        !isSvg && `url('${materialOptions['exterior_color']}')`
                    }
                    transform={!isSvg && materialOptions['hor_grain_ext']}
                    src={getProductInfo('changedImage')}
                    fallbackSrc={getProductInfo('image')}
                    alt={getProductInfo('typeName')}
                />
            </PlaceholderImageContainer>
        </Preview3DContainer>
    );
};

const Preview3DContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 365px;
    display: flex;
    border-radius: 8px;
    border: 2px solid rgb(var(--primary_colour));
    padding: 35px;
`;

const PlaceholderImageContainer = styled.div`
    font-weight: 600;
    text-align: center;
    color: #757575;
    font-size: 22px;
    width: 350px;
    height: 350px;
`;

export default NoPreview3DPlaceholder;
